$(document).ready(function() {
    var startTime = new Date($("#elapsedTime").attr("start_time")).getTime();

    function updateTimer() {
        var currentTime = new Date().getTime();
        var elapsedTime = currentTime - startTime;

        var minutes = Math.floor(elapsedTime / (1000 * 60));
        var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

        minutes = String(minutes).padStart(2, '0');
        seconds = String(seconds).padStart(2, '0');

        $("#elapsedTime").text(minutes + ":" + seconds);

        setTimeout(updateTimer, 1000);
    }

    updateTimer();
});