$(document).ready(function() {
    $('.js-example-basic-single').select2();
});

$(document).ready(function() {
    $('#add-timer').on('shown.bs.modal', function () {
        $('.js-example-basic-single').select2({
            dropdownParent: $("#add-timer")
        });
    });
});
