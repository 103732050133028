$(document).ready(function(){
    $('.main-menu').click(function(){
        var mainMenuId = $(this).attr('main-menu-id');
        $('.sub-menu').removeClass('active-sub-menu');
        $('.sub-menu[sub-menu-id="'+mainMenuId+'"]').addClass('active-sub-menu');
    });

    $('#notification-indicator').click(function(){
        $('.events-content').toggleClass('active');
    });
});