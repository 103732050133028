
$(document).ready(function() {
    $('.copy').click(function() {
        var text = $(this).text();
        navigator.clipboard.writeText(text)
            .then(function() {
                console.log('Másolás sikerült: ' + text);
                Swal.fire({
                    icon: 'success',
                    title: 'Sikeres másolás!',
                    text: 'Az érték másolva lett: ' + text,
                    showConfirmButton: false,
                    timer: 2000,
                    background: '#323f4b',
                    color: '#fff'
                });
            })
            .catch(function(err) {
                console.error('Másolás sikertelen: ' + err);
                Swal.fire({
                    icon: 'error',
                    title: 'Hiba történt',
                    text: 'Hiba történt a másolás közben.'
                });
            });
    });
});
