document.addEventListener("DOMContentLoaded", function() {
    var buttons = document.querySelectorAll('a[href^="#"]');
    
    buttons.forEach(function(button) {
        button.addEventListener("click", function(event) {
            event.preventDefault();
            var targetId = this.getAttribute("href");
            var targetElement = document.querySelector(targetId);
            if (targetElement) {
                var offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset - 132;
                window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth"
                });
            }
        });
    });
});
